import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/photography/dodge-burn-and-sponge-tools-in-photoshop-cs6",
  "date": "2013-08-15",
  "title": "DODGE BURN AND SPONGE TOOLS IN PHOTOSHOP CS6",
  "author": "admin",
  "tags": ["design", "photography", "photoshop"],
  "featuredImage": "feature.jpg",
  "excerpt": "The Dodge, Burn, and Sponge tools in Photoshop CS 6 can be used to correct the lighting of an image. Let’s look at each of these tools and what they are useful for."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul"><strong parentName="li">{`Dodge Tool`}</strong>{` - Lighten an area`}</li>
      <li parentName="ul"><strong parentName="li">{`Burn Tool`}</strong>{` - Darken an area`}</li>
      <li parentName="ul"><strong parentName="li">{`Sponge Tool`}</strong>{` - Desaturate/saturate color`}</li>
    </ul>
    <p>{`With these tools a photographer can enhance a photo by retouching the areas affected by strong light, harsh shadows and bright/weak colours.`}</p>
    <h2>{`Dodge Tool`}</h2>
    <p>{`Dodge Tool is grouped with Burn and Sponge Tools. To use the last Dodge, Burn, Sponge Tools, use the keyboard shortcut of “O”.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2013-08-15-dodge-burn-and-sponge-tools-in-photoshop-cs6/ps_dodgeburn.jpg",
        "alt": "Dodge Toolobox"
      }}></img></p>
    <p>{`Once you selected this tool,  an options bar will be displayed on the top of the window, showing `}<inlineCode parentName="p">{`Range`}</inlineCode>{` and `}<inlineCode parentName="p">{`Exposure`}</inlineCode>{` drop downs.`}</p>
    <h3>{`Range`}</h3>
    <p>{`This specifies if the Dodge tool affects shadows, mid-tones, or highlights in the areas you use the tool.`}</p>
    <h3>{`Exposure`}</h3>
    <p>{`Specifies the effect the Dodge tool makes as you drag. Always start with a lower exposure, so that you can drag it over again if the areas needs a strong effect.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2013-08-15-dodge-burn-and-sponge-tools-in-photoshop-cs6/airbrush.jpg",
        "alt": "Airbrush"
      }}></img></p>
    <p>{`Simulates Airbrush stroke.`}</p>
    <h3>{`Protect Tones`}</h3>
    <p>{`Tells Photoshop to minimize the clipping and makes sure the colours do not change.  You can leave this unchecked for most scenarios.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2013-08-15-dodge-burn-and-sponge-tools-in-photoshop-cs6/dodge-toolbar.jpg",
        "alt": "Dodge Tool Options"
      }}></img></p>
    <p>{`Take a look at the image below. See how the dark areas  and shadows are lightened by the Dodge Tool, bright areas which do not need attention are darkened with the Burn Tool and background colours have been desaturated to focus on the subject with the Sponge Tool.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2013-08-15-dodge-burn-and-sponge-tools-in-photoshop-cs6/dodgeburn.jpg",
        "alt": "Finished Dodge Tool Example"
      }}></img></p>
    <h2>{`Burn Tool`}</h2>
    <p>{`Opposite of the Dodge Tool, Burn tool darken the image. Right-click the toolbox again and select the Burn Tool.`}</p>
    <p>{`The options are exactly the same as the Dodge Tool.`}</p>
    <h2>{`Sponge Tool`}</h2>
    <p>{`The Sponge Tool allows you to desaturate or saturate the colours. Options bar for Sponge tool is a little bit different, so let’s go over each of the options.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2013-08-15-dodge-burn-and-sponge-tools-in-photoshop-cs6/sponge-toolbar.jpg",
        "alt": "Sponge Tool Options"
      }}></img></p>
    <p><inlineCode parentName="p">{`Mode`}</inlineCode>{` specifies whether the Sponge tool saturates (adds more color intensity) or desaturates (decreases color intensity) in the area you drag over.`}</p>
    <p><inlineCode parentName="p">{`Flow`}</inlineCode>{` identifies how much of a change the Sponge tool makes as you drag.`}</p>
    <h3>{`Where to use these tools`}</h3>
    <p><inlineCode parentName="p">{`Sponge Tool`}</inlineCode>{` - Use the Sponge tool to desaturate to make certain areas of a picture be black and white. For example, use desaturate to take a picture of an object (example: red rose) or one coloured object and create a selection around that object. Then inverse the selection (this makes it so everything but the object can be modified). Now desaturate everything else. Your end result is a black and white picture with an object in the middle (very cool effect).`}</p>
    <p><inlineCode parentName="p">{`Burn Tool`}</inlineCode>{` - Fix bright areas, and also you can make shadows appear to be even darker than they were photographed.`}</p>
    <p><inlineCode parentName="p">{`Dodge Tool`}</inlineCode>{` - You could use this tool to fake sunlight on an object. Sometimes, you are merging 2 pictures together and picture 1 which ends up being the background has some sunlight in it. Picture 2 has no sunlight in it. That is a great time to pull out the Dodge tool and add some specific light to the image.`}</p>
    <p>{`These tools will be taking a regular part in your toolbox when it comes to enhancing image. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      